import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../../components/layout";
import { Footer } from "../../components/03_organisms/footer";
import { Bio } from "../../components/03_organisms/bio";
import appIcon from "./mdediter/icon.png";
import appScreenshot from "./mdediter/sc-01.png";
import memoScreenshot from "./mdediter/sc-02.png";
import previewScreenshot from "./mdediter/sc-04.png";

import styles from "./mdediter-style.module.css";

const MdEditerLpPage = ({ data }) => {
  //  console.log(data)
  const pageTitle = "マークダウンエディタ「skeleton md editer」をリリース";
  const pageDescription =
    "マークダウンエディタ「skeleton md editer」をリリースしました。よく使う記号を簡単に入力できるボタンと、リアルタイムプレビューを搭載しています";
  return (
    <Layout>
      <Helmet
        htmlAttributes={{ lang: "ja" }}
        title={pageTitle}
        meta={[
          { name: "description", content: pageDescription },
          { name: "keywords", content: data.site.siteMetadata.keywords },
          { property: "og:title", content: pageTitle },
          { property: "og:description", content: pageDescription },
          { property: "og:url", content: data.site.siteMetadata.siteUrl },
          { property: "og:type", content: "website" },
          { property: "og:site_name", content: data.site.siteMetadata.title },
          { property: "twitter:card", content: "summary" },
          {
            property: "twitter:site",
            content: data.site.siteMetadata.twitterUsername,
          },
          {
            property: "google-site-verification",
            content: data.site.siteMetadata.google_site_verification,
          },
          {
            property: "og:image",
            content: data.site.siteMetadata.siteUrl + appIcon,
          },
          //{ property: 'og:image', content: mushiIcon },
        ]}>
        <script
          async
          src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
        <link
          href="https://fonts.googleapis.com/css?family=Lemon&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <div className={`${styles.descBack} ${styles.descMain}`}>
        <div className={styles.descFlex}>
          <h1 className={styles.appName}>skeleton md editer</h1>
          <img width="400px" src={appIcon}  alt="アプリアイコン"/>
          <div className={styles.download}>
            <div className={`${styles.desc} ${styles.descPub}`}>
              <a
                target="_blank"
                rel="noreferrer"
                className={styles.playLink}
                href="https://play.google.com/store/apps/details?id=com.honmushi.mdediter&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  alt="Google Play で手に入れよう"
                  src="https://play.google.com/intl/en_us/badges/images/generic/ja_badge_web_generic.png"
                />
              </a>
            </div>
            <div className={`${styles.desc} ${styles.descPub}`}>
              <a
                target="_blank"
                rel="noreferrer"
                className={styles.appLink}
                href="https://apps.apple.com/us/app/skeleton-md-editer/id1480465771?l=ja&ls=1">
                <img
                  alt="app store"
                  width="260px"
                  src="https://linkmaker.itunes.apple.com/ja-jp/badge-lrg.svg?releaseDate=2019-09-01&kind=iossoftware&bubble=ios_apps"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.descBack} ${styles.descMain}`}>
        <div className={styles.descFlex}>
          <h3>
            スマートフォンでマークダウンを編集するために、エディタを作りました。
          </h3>
          <div className={styles.download}>
            <div className={styles.descImage}>
              <img src={appScreenshot} alt="マークダウンの編集に特化したエディタアプリ"/>
            </div>
            <div className={styles.desc}>
              <p>
                マークダウンの編集に特化したエディタアプリです。
                <br />
                スマホの小さな画面、画面内の小さなキーボードでもマークダウン編集を快適にできるように設計しています。
              </p>
              <p>
                簡単なメモからreadmeの作成などさまざまな用途に利用できます。
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.descBack} ${styles.descSub}`}>
        <div className={styles.flexRow}>
          <div className={styles.descSnapshot}>
            <img src={previewScreenshot} alt="リアルタイムでプレビューが確認できます"/>
          </div>
          <div className={styles.descText}>
            <h3>リアルタイムプレビュー</h3>
            <p>
              右上のボタンを押すことで、リアルタイムプレビューを表示できます。
              <br />
              もう一度押すと非表示になります。
            </p>
            <p>
              画面右側にプレビューが表示され、簡易HTMLの表示を確認しながらテキスト編集を行えます。テキストの変更は即時プレビューに反映されます。
            </p>
          </div>
        </div>
      </div>
      <div className={`${styles.descBack} ${styles.descSub}`}>
        <div className={styles.flexRowReverse}>
          <div className={styles.descSnapshot}>
            <img src={memoScreenshot} alt="便利な記号入力ボタン"/>
          </div>
          <div className={styles.descText}>
            <h3>記号入力ボタン</h3>
            <p>
              スマホのキーボードでは入力しにくい記号の入力を支援するボタンです。
            </p>
            <p>
              #,~,+,-,*などのよく使われる記号を、画面下部のボタンで入力できます。
            </p>
            <p>
              別アプリへの共有やクリップボードへコピーするボタンも設置しました。
            </p>
          </div>
        </div>
      </div>

      <div className={styles.descBack}>
        <div className={styles.desc}>
          <h2>ダウンロードはこちらから</h2>
          <div className={styles.download}>
            <div className={`${styles.desc} ${styles.descPub}`}>
              <a
                target="_blank"
                rel="noreferrer"
                className={styles.playLink}
                href="https://play.google.com/store/apps/details?id=com.honmushi.mdediter&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar25151">
                <img
                  alt="Google Play で手に入れよう"
                  src="https://play.google.com/intl/en_us/badges/images/generic/ja_badge_web_generic.png"
                />
              </a>
            </div>
            <div className={`${styles.desc} ${styles.descPub}`}>
              <a
                target="_blank"
                rel="noreferrer"
                className={styles.appLink}
                href="https://apps.apple.com/us/app/skeleton-mdEditer/id1480465771?l=ja&ls=1">
                <img
                  alt="app store"
                  width="260px"
                  src="https://linkmaker.itunes.apple.com/ja-jp/badge-lrg.svg?releaseDate=2019-0901&kind=iossoftware&bubble=ios_apps"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.descBack} ${styles.descWhite}`}>
        <div className={styles.desc}>
          <Bio />
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query {
    site: site {
      siteMetadata {
        title
        keywords
        description
        siteUrl
        twitterUsername
        google_site_verification
      }
    }
  }
`;
export default MdEditerLpPage;
